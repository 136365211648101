{
  "name": "@site-mate/sitemate-flowsite-frontend-client-v2",
  "version": "24.32.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "exit 0",
    "build-ui": "node --max-old-space-size=8192 node_modules/@angular/cli/bin/ng build --configuration production --source-map true",
    "configure": "node ./node_modules/dotenv-cli/cli.js -e ../../.env.overrides -e ../../.env -- cross-env TS_NODE_PROJECT=tsconfig.helpers.json node -r ts-node/register ./scripts/configure.ts",
    "watch": "ng build-ui --watch",
    "test": "jest --silent --runInBand --detectOpenHandles",
    "test:single": "jest --verbose --runInBand --detectOpenHandles --testPathPattern",
    "test:logs": "jest --verbose --runInBand --detectOpenHandles"
  },
  "private": true,
  "dependencies": {
    "@site-mate/dashpivot-shared-library": "24.0.138",
    "@site-mate/sitemate-flowsite-shared": "workspace:*",
    "@site-mate/sitemate-global-shared": "workspace:*",
    "@site-mate/sitemate-infra-shared": "workspace:*",
    "@site-mate/global-web-ui": "17.8.1",
    "@angular/animations": "17.3.0",
    "@angular/common": "17.3.0",
    "@angular/compiler": "17.3.0",
    "@angular/core": "17.3.0",
    "@angular/forms": "17.3.0",
    "@angular/localize": "17.3.0",
    "@angular/platform-browser": "17.3.0",
    "@angular/platform-browser-dynamic": "17.3.0",
    "@angular/router": "17.3.0",
    "@angular/cdk": "17.3.0",
    "angular-auth-oidc-client": "17.0.0",
    "@bugsnag/js": "7.22.4",
    "@bugsnag/browser-performance": "2.10.1",
    "@bugsnag/plugin-angular": "7.22.4",
    "@stoplight/elements-dev-portal": "2.2.0",
    "dayjs": "1.11.13",
    "dompurify": "3.1.5",
    "lodash": "4.17.21",
    "openid-client": "5.4.2",
    "rxjs": "7.8.0",
    "rrule": "2.8.1",
    "tslib": "2.3.0",
    "ulid": "2.3.0",
    "zone.js": "0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.0",
    "@angular/cli": "17.3.0",
    "@angular/compiler-cli": "17.3.0",
    "@faker-js/faker": "8.0.2",
    "@types/dompurify": "3.0.5",
    "@types/jasmine": "5.1.0",
    "@types/jest": "29.5.2",
    "@types/lodash": "4.14.194",
    "@types/node": "20.11.30",
    "@typescript-eslint/eslint-plugin": "6.1.0",
    "@typescript-eslint/parser": "6.1.0",
    "eslint": "8.45.0",
    "eslint-config-airbnb": "19.0.4",
    "eslint-config-airbnb-typescript": "17.0.0",
    "eslint-config-prettier": "8.8.0",
    "eslint-config-airbnb-base": "15.0.0",
    "eslint-plugin-import": "2.27.5",
    "eslint-plugin-prettier": "5.0.0",
    "eslint-plugin-storybook": "0.8.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-promise": "6.1.1",
    "eslint-plugin-jest": "28.2.0",
    "prettier": "3.2.5",
    "prettier-plugin-tailwindcss": "0.5.12",
    "tailwindcss": "3.4.1",
    "jasmine-core": "5.1.0",
    "jest": "29.7.0",
    "jest-preset-angular": "14.0.3",
    "jest-silent-reporter": "0.5.0",
    "karma": "6.4.0",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.0",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "stylelint-config-prettier": "9.0.5",
    "typescript": "5.4.2",
    "@aws-sdk/client-secrets-manager": "3.654.0",
    "dotenv-cli": "~7.4.1",
    "cross-env": "~7.0.3"
  }
}
